import React from 'react'
import "./main.css";

const Banner = () => {
  return (
    <div className='main-banner'>

      <div className=' main-banner-content'>
      <h2>
      Home Services , On Demand.
        </h2>
      

      </div>


    </div>
  )
}

export default Banner
