import React from 'react'
import "./footer.css";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
const Footer = () => {
  return (
    <>
      <div className='footer-footer'>
        <div className='container footerr'>

          <div className=' fw-semibold'>
            <p> Copyright &copy; 2023. All Rights Reserved</p>
          </div>

          {/* <div className='social-icons'>
          <Icon icon="ant-design:linkedin-outlined" color="white" width="24" height="24" />
          <Icon icon="ant-design:facebook-outlined" color="white" width="24" height="24" />
          <Icon icon="gg:instagram" color="white" width="24" height="24" />


          </div> */}
        </div>

        
     
    </div>

    </>
  )
}

export default Footer
