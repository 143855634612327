import React from 'react'
import { Icon } from '@iconify/react';
import image1 from "../../assets/Services/Painting .png"
import image3 from "../../assets/Services/waterproofing-services.jpeg"
import image2 from "../../assets/Services/Plumbering .png"
import image4 from "../../assets/Services/Carpentery.jpeg"
import { Link } from 'react-router-dom';


const Services = () => {
  return (
    <div className="services-main">
      <div className='container'>
        <div className='row text-center '>
                <h2 className='fw-semibold'>Our Services</h2>
        </div>

        <div className='row'>
              <div className='col-lg-3 col-md-6 col-sm-6 col-11 mx-auto '>
              <div className='services-section'>
          
                <div className='services-section-content'>
                <img src = {image1} className='img-fluid' alt="service-image"></img>
                    <h5>Painting Work</h5>
                   
                </div>
              </div>
              </div>

              <div className='col-lg-3 col-md-6 col-sm-6 col-11 mx-auto '>
              <div className='services-section'>
         
                <div className='services-section-content' >
                <img src = {image2} className='img-fluid' alt="service-image"></img>
                    <h5>Plumbing Work</h5>
                  
                </div>
              </div>
              </div>

              <div className='col-lg-3 col-md-6 col-sm-6 col-11 mx-auto '>
              <div className='services-section'>
         
                <div className='services-section-content' >
                <img src = {image3} className='img-fluid' alt="service-image"></img>
                    <h5>Waterproofing Work</h5>
                 
                </div>
              </div>
              </div>

              <div className='col-lg-3 col-md-6 col-sm-6 col-11 mx-auto '>
              <div className='services-section'>
         
                <div className='services-section-content' >
                <img src = {image4} className='img-fluid' alt="service-image"></img>
                    <h5>Carpentery Work</h5>
                  
                </div>
              </div>
              </div>

             

              

             

          

            

              

        </div>




        <div className='service-button'>
          <Link to = "/services">More Services...</Link>
        </div>

      </div>
    </div>
  )
}

export default Services
