import React, { useEffect } from 'react'
import image from "../../assets/mainpage/logopng.png";
import './header.css';
import { Link } from 'react-router-dom';
import jQuery from 'jquery';
const Header = () => {


  useEffect(()=>{
    jQuery(window).on("scroll", function() {
      if(jQuery(window).scrollTop() > 100) {
        jQuery(".header").addClass("onscroll");
      } else {
        //remove the background property so it comes transparent again (defined in your css)
      jQuery(".header").removeClass("onscroll");
      }
    });
  })

  return (
    <header id="header" className='header '>
      <div className='container mt-1'>
        <div className='row alldisplayflex'>
        <div className='col-lg-4 col-sm-6 col-6 main-logo'>
         <Link to = "/" > <img src={image} className=''></img></Link>
        </div>

        <div className='col-lg-4 col-sm-6 col-6'>
          <div className='header-center-menu'>
            <Link to="/">Home</Link> 
            <Link to="/services">Services</Link>
          </div>
        </div>

        <div className='col-lg-4 col-sm-12'>
        <div className='header-end-menu'>
            <p>
              Call Us at :
            </p>

            <span>
              +91 94220 34465
            </span>
          </div>
        </div>
        </div>
      </div>
    </header>
  )
}

export default Header
