import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import "./about.css";
import image from "../../assets/about/mainImage.jpg"
import image2 from "../../assets/about/map.jpg"
import { Icon } from '@iconify/react';
import Testimonial from '../MainPage/Testimonial';
const About = () => {
  return (
    <div className='about'>
      <Header />
      
      <section className='about-banner'>
        <div className='container'>
          <div className='row'>
            <h1> Experienced Professionals Dedicated to Your Home's Needs</h1>
          </div>
        </div>
      </section>

      <section className='about-content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <h2 className='fw-semibold'>What we do?</h2>
              <p>
                <strong> Plumbing Services:</strong> This includes a wide range of plumbing solutions, such as fixing leaks, repairing or replacing pipes, installing and maintaining water heaters, unclogging drains, and handling plumbing emergencies.
              </p>

              <p>
                <strong>Electrical Services:</strong> Your business might also offer electrical services, such as electrical repairs, installations, lighting solutions, circuitry work, and safety inspections.
              </p>

              <p>
                <strong> HVAC Services: </strong>Heating, Ventilation, and Air Conditioning services may be part of your offerings. This includes HVAC system installations, repairs, maintenance, and air quality solutions.
              </p>

              <p>
                <strong>Maintenance Contracts:</strong> Offering maintenance contracts to homeowners or businesses can be a part of your services, ensuring regular check-ups and preventive maintenance for their plumbing, electrical, and HVAC systems.
              </p>
            </div>

            <div className='col-lg-6'>
              <img src={image} alt='image' className='img-fluid'></img>
            </div>
          </div>
        </div>
      </section>

      <Testimonial />

      <section className='about-history'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <img src={image2} alt='image' className='img-fluid'></img>
            </div>
            <div className='col-lg-6'>
              <h2 className='fw-semibold'>Our History</h2>
              <p>
                <strong>
                  Founding Story:
                </strong>

                In 2005, John Smith and Lisa Johnson, two experienced plumbing and HVAC technicians, joined forces after realizing the need for reliable and customer-focused services in their local community. Their passion for providing top-notch solutions led to the creation of "Smith & Johnson Services."
              </p>
              <p>
                <strong>
                  Growth and Evolution:
                </strong>

                Over the years, Smith & Johnson Services expanded its service offerings beyond plumbing to include electrical and HVAC solutions, becoming a one-stop-shop for homeowners and businesses alike. Embracing advanced technology, the company introduced innovative tools for faster diagnostics and efficient repairs.
              </p>
              <p>
                <strong>
                  Company Philosophy:
                </strong>
                At Smith & Johnson Services, we believe in providing exceptional craftsmanship and unmatched customer service. Our focus is on building lasting relationships with our clients by delivering reliable solutions with a smile.
              </p>
            </div>
          </div>
        </div>
      </section>

     
      <Footer />
    </div>
  )
}

export default About




