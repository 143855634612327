import React from 'react'
import image1 from "../../assets/mainpage/jayeshpatel.png"
import image2 from "../../assets/mainpage/unknownpatel.png"
const Aboutsection = () => {
  return (
    <div>
      <div className='container'>
        <div className='row '>
              <div className='about-row'>
              <div className='col-lg-12 pt-3 pb-2 '>
                <div className='about-section1'>
                  <div className='row '>
                    <div className='col-lg-4  col-sm-12 text-center'>
                      <img src={image2} alt="Handy-Man Image" className='' />
                     
                    </div>
                    <div className='col-lg-8 col-sm-12 info-text'>

                    <h5>
                    Urmish Patel
                      </h5>

                      <h6>
                      9422034465
                      </h6>
                      <p>patelub58@gmail.com</p>

                     
                    </div>
                  </div>

                </div>
              </div>

              <div className='col-lg-12 pb-3  '>
                <div className='about-section2'>
                  <div className='row '>
                    <div className='col-lg-4  col-sm-12 text-center'>
                      <img src={image1} alt="Handy-Man Image" className='' />
                      
                    </div>
                    <div className='col-lg-8 col-sm-12 info-text'>
                    <h5>
                        Jayesh Patel
                      </h5>
                    <h6>
                    +16474634094      
                      </h6>
                    <p>jay_jbp@yahoo.com</p>

                     
                    </div>
                  </div>
                </div>
              </div>




            </div>
            </div>
          </div>
        </div>
     
  )
}

export default Aboutsection
