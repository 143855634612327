import React from 'react'
import "./services.css";
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import image1 from "../../assets/mainpage/image1.jpg";
import image2 from "../../assets/Services/cleaning.jpg";
import Contact from '../MainPage/Contact';
import Painting from "../../assets/Services/painting1.jpg"
import waterproofing from "../../assets/Services/waterproofing-services.jpeg"
import Plumbering from "../../assets/Services/Plumbering .png"
import Carpentery from "../../assets/Services/Carpentery.jpeg"
import aluminium from "../../assets/Services/aluminium-doors-manufacturers.jpeg"
import cleaning from "../../assets/Services/tiling .jpeg"
import fabrication from "../../assets/Services/fabriction.jpeg"
import road from "../../assets/Services/road.jpeg"
import reno from "../../assets/Services/reno.jpg"

const Services = () => {
    return (
        <div>
            <Header />
            <div className=' servicesmain'>
                {/* <h1>Comprehensive Range of Premium Services Catering to Your Diverse Needs.</h1> */}
            </div>


            <section class="we-offer-area text-center bg-gray">
                <div class="container">

                    <div className='row mt-3'>
                        <h2>What We offer?</h2>
                        <p>Our passion is to repair your home the right way. Solving all of your maintenance and repair work. <br />
                            Quality and fast service is our motto .</p>
                    </div>


                    <div className='row'>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Painting Work</h5>

                                    <br />
                                    <span className=''>
                                        Painting-Home, offices, Shops etc

                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={Painting} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Home And Bathroom Renovations</h5>

                                    <br />
                                    <span className=''>
                                    Trust in us for quality work and fair price for your renovations as we are home improvement specialist

                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={reno} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Plumbing Work</h5>

                                    <br />
                                    <span className=''>
                                    All plumbing solutions will be provided
                                    </span>

                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={Plumbering} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Waterproofing</h5>
                                    <br />
                                    <span >
                                    Waterproofing-bathroom, balcony and terraces 

                                    </span>
                                
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={waterproofing} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Carpentery</h5>
                                    {/* <small>
                                        <Icon icon="ic:outline-star" color="#f5c454" width="12" height="12" />    lorem ipusm
                                    </small> <br />
                                    <small>
                                        <Icon icon="ic:outline-star" color="#f5c454" width="12" height="12" />     lorem ipusm
                                    </small> */}
                                    <br />

                                    <span className=''>
                                    Quality, carpentry work will be provided
                                    </span>




                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={Carpentery} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Fabrication work</h5>
                                    <br />
                                    <span className=''>
                                    Door, sliding windows et cetera 

                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={fabrication} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Tiling work</h5>
                                    <br />
                                    <span className=''>
                                    Commercial non-commercial, all kinds of tile work will be provided

                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={cleaning} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Road Work</h5>
                                    <br />
                                    <span className=''>
                                    Road work-society internal work etcetera

                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={road} className='img-fluid' />
                                </div>
                            </div>
                        </div>


                        <div className='col-md-6 '>
                            <div className='row servicess'>
                                <div className='col-md-8 services-content'>
                                    <h5>Aluminium doors and windows work</h5>
                                    <br />
                                    <span className=''>
                                    Aluminium doors and windows-quality, aluminium doors and windows will be provided




                                    </span>
                                </div>

                                <div className='col-md-4 serviceimage'>
                                    <img src={aluminium} className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Contact />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Services
