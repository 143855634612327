import React from 'react';
import { Swiper, SwiperSlide,  } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const Testimonial = () => {
  return (
<div className='testimonial-main'>
<div className='container '>
        <div className='row mt-3 mb-3' style={{color:"white"}}>
            <h2 className='text-center fw-semibold'>Testimonial</h2>
        </div>

   <div className='row mb-3'>
        <div className='col-lg-3'>
        <div className='testimonial'>
           <p>Shree Home Inspection’s electricians fixed all the wires in my old house and made it safe. They did the job quickly and made sure everything was good. Now, my house is safe and works well.</p>
           <p></p>
            {/* <h4>Lorem Ipsum</h4> */}
           </div>
        </div>
   

      <div className='col-lg-3'>
      <div className='testimonial'>
           <p>Shree Home Inspection’s painters made our house look so nice. They painted the walls and made them colorful. They did the work well and finished it on time. Our house looks great now.</p>
            {/* <h4>Lorem Ipsum</h4> */}
           </div>
        </div>
     
 
      <div className='col-lg-3'>
      <div className='testimonial'>
           <p>When our lights stopped working, Shree Home Inspection’s electricians came fast and fixed them. They knew what they were doing, and they were really nice. We’ll call them again if we need help.</p>
            {/* <h4>Lorem Ipsum</h4> */}
           </div>
        </div>
     
      <div className='col-lg-3'>
           <div className='testimonial'>
           <p>We had a big problem with water in our house, but Shree Home Inspection’s plumbers fixed it fast. They did a good job, and we’re happy with their work.</p>
            {/* <h4>Lorem Ipsum</h4> */}
           </div>
        </div>
        </div>
    </div>

</div>
  )
}

export default Testimonial
