import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/compat/app'; // Import Firebase core
import 'firebase/compat/auth'; // Import Firebase Authentication if needed
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD6t6oms_Cvyy5pf7sIQCZ-VOF5OhvtD0A",
  authDomain: "shree-inspections.firebaseapp.com",
  projectId: "shree-inspections",
  storageBucket: "shree-inspections.appspot.com",
  messagingSenderId: "377447495408",
  appId: "1:377447495408:web:4f3ab451c90f6a7c923435",
  measurementId: "G-D2NLQZ5QXR"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

const Contact = () => {
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    companyName: Yup.string().required('Company Name is required'),
    message: Yup.string().min(20, 'Message should be at least 20 characters').required('Message is required'),
  });

  const handleSubmit = async (values, { resetForm }) => {

    console.log(values)
    
    try {
      // Store the form data in Firestore
      await db.collection('contacts').doc(values.email).set(values);
      console.log('Form data stored in Firestore successfully.');
  
      // Compose the email using the mailto URL
      const emailSubject = encodeURIComponent('Contact Form Shree Inspection');
      const emailBody = encodeURIComponent(`Name: ${values.fullName}\nEmail: ${values.email}\nMessage: ${values.message}`);
      const mailtoUrl = `mailto:manitlubmani@gmail.com?subject=${emailSubject}&body=${emailBody}`;
  
      // Open the default email client with the mailto URL
      window.location.href = mailtoUrl;
  
      // Reset the form
      resetForm();
    } catch (error) {
      console.error('Error storing form data in Firestore:', error);
    }
  }
  

 

  return (
    <div className="contact-formm">
      <div className="container">
        <div className="contain">
          <div className="wrapper">
            <div className="form">
              <h4>GET IN TOUCH</h4>
              <h2 className="form-headline">Send us a message</h2>
              <Formik
                initialValues={{
                  fullName: '',
                  email: '',
                  companyName: '',
                  message: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {() => (
                  <Form id="submit-form" className="formm" action="">
                    <p>
                      <Field
                        id="name"
                        name="fullName"
                        className="form-input"
                        type="text"
                        placeholder="Your Name*"
                      />
                      <ErrorMessage className="errormessage" name="fullName" component="small" />
                    </p>
                    <p>
                      <Field
                        id="email"
                        name="email"
                        className="form-input"
                        type="email"
                        placeholder="Your Email*"
                      />
                      <ErrorMessage className="errormessage" name="email" component="small" />
                    </p>
                    <p className="full-width">
                      <Field
                        id="company-name"
                        name="companyName"
                        className="form-input"
                        type="text"
                        placeholder="Company Name*"
                      />
                      <ErrorMessage className="errormessage" name="companyName" component="small" />
                    </p>
                    <p className="full-width">
                      <Field
                        id="message"
                        name="message"
                        className="form-input"
                        as="textarea"
                        cols="30"
                        rows="7"
                        placeholder="Your Message*"
                      />
                      <ErrorMessage className="errormessage" name="message" component="small" />
                    </p>
                    <p className="full-width">
                      <input type="submit" className="submit-btn" value="Submit" />

                      <button type="reset" className="reset-btn">
                        Reset
                      </button>
                    </p>
                  </Form>
                )}
              </Formik>
            </div>

            <div className="contacts contact-wrapper">
              <ul>
                <h4>Contact Info.</h4>
                <h6>Om Avishkar Society, Kothrud, Pune</h6>
                <span className="hightlight-contact-info">
                  <li className="email-info">
                    <i className="fa fa-envelope" aria-hidden="true"></i> shreehomeinspection@gmail.com
                  </li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>{' '}
                    <span className="highlight-text"> +91 94220 34465</span>
                  </li>
                </span>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
