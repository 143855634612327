import React from 'react';
import './contact.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Icon } from '@iconify/react';
import { Formik, Form, Field } from 'formik';

const Contact = () => {
  const isInputValueFilled = (value) => {
    return value.trim() !== '';
  };

  return (
    <div className="">
      <Header />

      <section className="contact-mainn">
        <div className="section-header">
          <div className="container">
            <h1>Contact Us</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="row contact-row">
            <div className="contact-info">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <Icon icon="teenyicons:pin-outline" color="#1da9c0" width="24" height="24" />
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                    4671 Sugar Camp Road, <br /> Owatonna, Minnesota, <br />55060
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <Icon icon="teenyicons:phone-outline" color="#1da9c0" width="24" height="24" />
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>571-457-2321</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <Icon icon="material-symbols:mail-outline" color="#1da9c0" width="24" height="24" />
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>ntamerrwael@mfano.ga</p>
                </div>
              </div>
            </div>

            <div className="contact-form">
              <Formik
                initialValues={{
                  fullName: '',
                  email: '',
                  phone: '',
                  message: '',
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.fullName) {
                    errors.fullName = 'Required';
                  }
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                    errors.email = 'Invalid email address';
                  }
                  // Add more validation rules as needed for other fields
                  return errors;
                }}
                onSubmit={(values) => {
                  // Handle form submission here (e.g., send data to server)
                  console.log(values);
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form id="contact-form">
                    <h2>Send Message</h2>
                    <div className={`input-box ${isInputValueFilled(values.fullName) ? 'active' : ''}`}>
                      <Field type="text" name="fullName" onChange={handleChange} value={values.fullName} />
                      <span>Full Name</span>
                      {errors.fullName && touched.fullName && <div className="error">{errors.fullName}</div>}
                    </div>

                    <div className={`input-box ${isInputValueFilled(values.email) ? 'active' : ''}`}>
                      <Field type="email" name="email" onChange={handleChange} value={values.email} />
                      <span>Email</span>
                      {errors.email && touched.email && <div className="error">{errors.email}</div>}
                    </div>

                    <div className={`input-box ${isInputValueFilled(values.phone) ? 'active' : ''}`}>
                      <Field type="tel" name="phone" onChange={handleChange} value={values.phone} />
                      <span>Phone</span>
                      {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                    </div>

                    <div className={`input-box ${isInputValueFilled(values.message) ? 'active' : ''}`}>
                      <Field as="textarea" name="message" onChange={handleChange} value={values.message} />
                      <span>Type your Message...</span>
                      {errors.message && touched.message && <div className="error">{errors.message}</div>}
                    </div>

                    <div className="input-box">
                      <button className='contact-button' type="submit">Send</button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Contact;
