import React from 'react'

const Stripcontact = () => {
  return (
    <div className='text-center contact-strip'>
      <p>
      For Home Inspection and Maintenance, Call at : <span>+91 9422034465</span>
      </p>
    </div>
  )
}

export default Stripcontact
