import React from 'react'
import Header from '../../Components/Header/Header'
import Footer from '../../Components/Footer/Footer'
import Banner from './Banner'
import Aboutsection from './Aboutsection'
import Stripcontact from './Stripcontact'
import Services from './Services'
import Contact from './Contact'
import Testimonial from './Testimonial'

const Main = () => {
  return (
    <div>
      <Header />
      <Banner />
      <Stripcontact />
      <Services />
      <Aboutsection />
    
      <Testimonial />
      <Contact />
      <Footer />
      
    </div>
  )
}

export default Main
