
import './App.css';
import Main from './Pages/MainPage/Main';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './Pages/About/About';
import Services from './Pages/Services/Services';
import Contact from './Pages/Contact/Contact';

function App() {
  return (
    <div className="App">
<Router>
  <Routes>
  <Route path="/" element={   <Main />}></Route>
  <Route path="/about" element={   <About />}></Route>
  <Route path="/services" element={   <Services />}></Route>
  <Route path="/contact" element={   <Contact />}></Route>
  </Routes>
</Router>
    </div>
  );
}

export default App;
